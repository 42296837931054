<template>
  <BContainer class="my-5">
    <BRow>
      <BCol class="mx-auto" lg="10">
        <BRow>
          <BCol>
            <Header1 title="Widgets" to="widget-create" />
            <WidgetList v-bind:active="active" />
          </BCol>
        </BRow>
        <BRow>
          <BCol class="my-5">
            <Header2 title="Traffic" />
            <WidgetEvent />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import WidgetEvent from "./WidgetEvent.vue";
import WidgetList from "./WidgetList.vue";
import Header1 from "./Header1.vue";
import Header2 from "./Header2.vue";

export default {
  components: {
    WidgetEvent,
    WidgetList,
    Header1,
    Header2
  },
  props: {
    active: {
      type: String,
      required: false
    }
  }
};
</script>
