<template>
  <BCard no-body class="border" bg-variant="dark">
    <BListGroup v-if="widgets.length" flush>
      <BListGroupItem
        class="d-flex flex-column"
        v-b-toggle="widget.id"
        v-bind:key="widget.id"
        v-for="widget in widgets"
      >
        <div class="d-flex flex-row">
          <div class="flex-grow-1">
            <BIconBookmarkStarFill variant="primary" />
            {{ widget.websiteName }}
            <BIconArrowRight variant="success" />
            {{ widget.reviewWebsiteName }}
            <BIconBullseye variant="danger" />
          </div>
          <div>mini-chart</div>
        </div>
        <BCollapse v-bind:id="widget.id" class="mt-2">
          <BCard class="border" bg-variant="dark">
            <p class="card-text">Collapse contents Here</p>
          </BCard>
        </BCollapse>
      </BListGroupItem>
    </BListGroup>
    <BListGroup v-else flush>
      <BListGroupItem class="d-flex flex-column border-none text-center">
        <small class="text-muted">
          Create a new widget and start tracking results.
        </small>
      </BListGroupItem>
    </BListGroup>
  </BCard>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    active: {
      type: String,
      required: false
    }
  },
  computed: {
    // ...mapState("alert", {
    //   fade: state => state.fade,
    //   message: state => state.message,
    //   show: state => state.show,
    //   variant: state => state.variant
    // }),
    ...mapState({
      loading: state => state.loading
    }),
    widgets: {
      get() {
        return this.$store.getters["widget/widgetsByUser"];
      }
    }
  }
  // data() {
  //   return {
  //     list: [
  //       {
  //         id: "1",
  //         reviewWebsite: "https://maps.google.com/abc-company/reivews",
  //         reviewWebsiteName: "Google Maps",
  //         website: "http://localhost/me"
  //       },
  //       {
  //         id: "2",
  //         reviewWebsite: "https://maps.google.com/abc-company/reivews",
  //         reviewWebsiteName: "Google Maps",
  //         website: "http://localhost/me"
  //       },
  //       {
  //         id: "3",
  //         reviewWebsite: "https://maps.google.com/abc-company/reivews",
  //         reviewWebsiteName: "Google Maps",
  //         website: "http://localhost/me"
  //       }
  //     ]
  //   };
  // }
};
</script>
